const config = {
    brand: {
        name: ['Burgerzaken','Gemeente','Slotendrecht'],
        imgSrc: './frontpage.jpg'
    }, 
    account: {
        imgSrc: './accountpage.jpg'
    },
    cards: [
        {
            imgSrc: './news.jpg',
            title: 'Huwelijk en partnerschap',
            subtitle: 'Om te trouwen moet u 4 dingen regelen met de gemeente.'
        }
    ]
}

export { config }